import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, Box, Grid, TextField, Typography, useMediaQuery, } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import parse from 'autosuggest-highlight/parse';
import 'i18n';
import { t } from 'i18next';
import throttle from 'lodash/throttle';
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import GoogleApiHelper from "../../../utils/GoogleApiHelper";
import { useTypedSelector } from '../../../utils/use-typed-selector';
import { LocationFormInfo, ParentClick } from "../EventDataForm";


interface GoogleApiLocationFormProps {
    googleApiKey: string;
    prePopulatedData: LocationFormInfo | undefined;
    // prePopulatedData: Address | undefined;
    locationError: string | null;
    setLocationError: (locationError: string | null) => void;
    setHasSelectedPlace: (hasSelectedPlace: boolean) => void;
    onLocationSelected: (locationFormInfo: LocationFormInfo | undefined) => void;
    locationRequiredError: boolean;
    setLocationRequiredError: (locationRequiredError: boolean) => void;
    label: string;
    requiredErrorText: string;
}


function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};
const placesService = {current: null};

export interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

export interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface GooglePlaceType {
    description: string;
    place_id: string;
    structured_formatting: StructuredFormatting;
}

export const GoogleApiLocationForm = forwardRef<ParentClick, GoogleApiLocationFormProps>((props, ref) => {

    const { googleApiKey, prePopulatedData, setHasSelectedPlace, onLocationSelected, setLocationError, locationError, locationRequiredError, setLocationRequiredError, label, requiredErrorText } = props;

    const theme = useTheme()
    const [selectedPlace, setSelectedPlace] = useState<GooglePlaceType | undefined>(prePopulatedData?.googleSelectedPlace ? prePopulatedData.googleSelectedPlace : undefined);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<readonly GooglePlaceType[]>([]);
    const loaded = useRef(false);
    const sessionToken = useRef<google.maps.places.AutocompleteSessionToken|undefined>(undefined);
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);

    const screenDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [placeholder, setPlaceholder] = useState(label)

    useEffect(() => {
        setPlaceholder(label)
    }, [label])

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );

        }
        loaded.current = true;
    }

    const fetchAutocomplete = useMemo(() =>
            throttle(
                (
                    request: {
                        input: string,
                        bounds?: google.maps.LatLngBounds,
                        componentRestrictions?: google.maps.places.ComponentRestrictions,
                        sessionToken: google.maps.places.AutocompleteSessionToken
                    },
                    callback: (results: readonly GooglePlaceType[]) => void,
                ) => {

                    setLoading(true);
                    (autocompleteService.current as any).getPlacePredictions(request, callback,);

                },
                1000, // Throttle time in ms
            ),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!placesService.current && (window as any).google) {
            placesService.current = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(selectedPlace ? [selectedPlace] : []);
            return undefined;
        }

        let defaultBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(48.056094, 1.181882),// south-west
            new google.maps.LatLng(49.525431, 3.567001) // north-east
        )
        // Create a new session token.
        if(!sessionToken.current) {
            sessionToken.current = new google.maps.places.AutocompleteSessionToken();
        }

        fetchAutocomplete({input: inputValue, bounds: defaultBounds, componentRestrictions: {country: 'fr'}, sessionToken: sessionToken.current}, (results?: readonly GooglePlaceType[]) => {
            if (active) {
                let newOptions: readonly GooglePlaceType[] = [];

                if (selectedPlace) {
                    newOptions = [selectedPlace];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
            setLoading(false)
        });

        return () => {
            active = false;
        };
    }, [selectedPlace, inputValue, fetchAutocomplete]);

    const convertGooglePlaceToLocationFormType = (selectedPlace: GooglePlaceType | undefined) => {
        if (!placesService.current && (window as any).google) {
            placesService.current = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
        }
        if(selectedPlace){
            return (placesService.current as any).getDetails({ placeId: selectedPlace.place_id, fields: ['address_component', 'formatted_address', 'name', 'geometry'] },
                (place: google.maps.places.PlaceResult) => {
    
                let addressMsg = GoogleApiHelper.transformGoogleAddressComponentToAddressMsg(place.address_components!, place.name!)
                
                onLocationSelected({
                    address: {
                        google_place_id: selectedPlace.place_id,
                        line1: addressMsg.getLine1(),
                        line2: addressMsg.getLine2(),
                        line3: addressMsg.getLine3(),
                        zip_code: addressMsg.getZipcode(),
                        city: addressMsg.getCity(),
                        coordinates: {
                            latitude: place.geometry!!.location!!.lat(),
                            longitude: place.geometry!!.location!!.lng()
                        },
                    },
                    addressLabel: place.name!!,
                    googleSelectedPlace: selectedPlace,
                    lat: place.geometry!!.location!!.lat(),
                    lng: place.geometry!!.location!!.lng()
                })
            });
        }else{
            onLocationSelected(undefined)
            setLocationRequiredError(true)
        }
    }

    return (
        <div className={`google-location-input-container theme--${appTheme}`}>
            <Autocomplete
                id="address-autocomplete"
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                forcePopupIcon={!screenDownSM} // Hide the arrow on smaller devices, taken from https://stackoverflow.com/questions/63266519/remove-autocomplete-drop-down-arrow
                filterOptions={(x) => x}
                options={options}
                blurOnSelect
                autoComplete
                loading={loading}
                loadingText={t("GLOBALS.Loading")}
                noOptionsText={t("GLOBALS.EnterAddress")}
                includeInputInList
                filterSelectedOptions
                value={selectedPlace}
                onMouseDownCapture={() => setPlaceholder(t('ORDERS.Billing.Placeholder') + ' *')}
                onBlur={() => setPlaceholder(label)}
                onChange={(event: any, newValue: GooglePlaceType | null) => {
                    setOptions(newValue ? [newValue, ...options] : options)
                    setSelectedPlace(newValue ?? undefined)
                    setHasSelectedPlace(newValue!==null)
                    setLocationRequiredError(false)
                    convertGooglePlaceToLocationFormType(newValue ?? undefined)
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                    setLocationRequiredError(false)
                    setLocationError(null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={placeholder}
                        error={locationRequiredError}
                        helperText={locationRequiredError ? requiredErrorText : ''}
                        />
                )}
                renderOption={(props, option) => {
                    let parts: {text: string, highlight: boolean}[] = []
                    if(option.structured_formatting.main_text_matched_substrings && option.structured_formatting.main_text_matched_substrings.length > 0) {
                        parts = parse(
                            option.structured_formatting.main_text,
                            option.structured_formatting.main_text_matched_substrings.map((match: any) => [match.offset, match.offset + match.length]),
                        );
                    }else {
                        parts.push({text: option.structured_formatting.main_text, highlight: false})
                    }
                    return (
                        <li {...props} className={`theme--${appTheme}`}>
                            <div className='location-search-line hoverable-bg clickable'>
                                <Box
                                    component={LocationOnIcon}
                                    className='location-icon'
                                />
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}>
                                                {part.text}
                                              </span>
                                    ))}
                                    <Typography variant="body2" color="text.primary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </div>
                        </li>
                    );
                }}
            />
        </div>
    );
})
GoogleApiLocationForm.displayName = 'GoogleApiLocationForm'
